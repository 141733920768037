'use client';

import { type NumberFlowProps } from '@number-flow/react';
import NumberFlow from '@number-flow/react';

export default function AnimatedNumbers({
  value,
  animated = true,
  ...props
}: NumberFlowProps & {
  value: number;
  animated?: boolean;
}) {
  return (
    <NumberFlow
      value={value}
      animated={animated}
      className="tracking-tight"
      {...props}
    />
  );
}

export function AnimatedCoins({
  value,
  animated = true,
}: {
  value: number;
  animated?: boolean;
}) {
  return (
    <AnimatedNumbers
      value={Number((value / 100).toFixed(2))}
      animated={animated}
      format={{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }}
    />
  );
}
