'use client';

export default function Component({ value = 3 }: { value?: number }) {
  // Calculate rotation angle (0 = -90deg, 6 = 90deg)
  const rotation = Math.min(
    Math.max((Math.min(value, 6) / 6) * 180 - 90, -90),
    90,
  );

  return (
    <div className="aspect-[2/1] w-full max-w-md">
      <svg viewBox="0 0 100 60" className="h-full w-full">
        {/* Colored arc */}
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#4ade80" />
            <stop offset="50%" stopColor="#facc15" />
            <stop offset="100%" stopColor="#f87171" />
          </linearGradient>
        </defs>

        {/* Gray backdrop arc */}
        <path
          d="M10 50 A 40 40 0 0 1 90 50"
          className="fill-none stroke-gray-700 stroke-[16]"
        />

        {/* Colored fill arc */}
        <path
          d="M10 50 A 40 40 0 0 1 90 50"
          className="fill-none stroke-[16]"
          stroke="url(#gradient)"
          strokeDasharray="126"
          strokeDashoffset="126"
          style={{
            strokeDashoffset: `${Math.max(0, 126 - (Math.min(value, 6) / 6) * 126)}`,
          }}
        />

        {/* Needle */}
        <g
          style={{
            transform: `rotate(${rotation}deg)`,
            transformOrigin: '50px 50px',
          }}
        >
          <path
            d="M50 50 L46 20 L50 16 L54 20 Z"
            className="origin-center fill-white stroke-white stroke-[1] rotate-180"
          />
          <circle cx="50" cy="50" r="6" className="fill-white" />
        </g>
      </svg>
    </div>
  );
}
