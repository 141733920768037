'use client';

import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';

import Gauge from './gauge';
import Typography from './ui/typography';

export default function BoxCardVolatility({ cov }: { cov: number }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="w-3/4">
          <Gauge value={cov} />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="isolate flex max-w-[300px] flex-col gap-2 bg-dialog p-4 text-white shadow-sm"
        sideOffset={8}
      >
        <Typography
          variant="p"
          affects="large"
          className="text-center font-black"
        >
          Volatility
        </Typography>

        <Typography variant="p" affects="xs">
          This represents the risk-to-reward ratio of the box. The higher the
          gauge, the higher the potential rewards compared to the cost and the
          higher the odds of losing your coins more quickly.
        </Typography>

        <div className="w-full">
          <Gauge value={cov} />
        </div>

        <Typography
          variant="p"
          affects="small"
          className="text-center font-bold"
        >
          Coefficient of Variation
        </Typography>
        <Typography variant="p" affects="small" className="text-center">
          {cov.toFixed(2)}
        </Typography>
      </PopoverContent>
    </Popover>
  );
}
